import qs from "querystring";
import request from "../../../app/utils/request";

export function fetchResortViewsApi() {
  return request.get("/dashboard/resort/views");
}

export function fetchDashboardResortByIdApi(id) {
  return request.get(`/dashboard/resort/${id}`);
}

export function fetchDashboardResortsApi(params) {
  return request.get(`/dashboard/resort?${qs.encode(params)}`);
}

export function createResortApi(data) {
  return request.post("/dashboard/resort", data);
}

export function updateResortApi(id, data) {
  return request.put(`/dashboard/resort/${id}`, data);
}

export function deleteResortApi(id) {
  return request.delete(`/dashboard/resort/${id}`);
}

export function createResortPictureApi(id, picture) {
  const data = new FormData();
  data.append("picture", picture);

  return request.post(`/dashboard/resort/${id}/picture`, data, {
    headers: { "content-type": "application/form-data" },
  });
}
