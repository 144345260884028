import { createSlice } from "@reduxjs/toolkit";
import { submitContactAPI } from "./contactApi";

const initialState = {
  submitContactLoading: false,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setSubmitContactLoading(state, action) {
      state.submitContactLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSubmitContactLoading } = contactSlice.actions;

export const submitContactUs = (data) => async (dispatch) => {
  dispatch(setSubmitContactLoading(true));
  try {
    await submitContactAPI(data);
    dispatch(setSubmitContactLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(setSubmitContactLoading(false));
    throw err;
  }
};

export default contactSlice.reducer;
