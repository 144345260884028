import { createSlice } from "@reduxjs/toolkit";
import {
  createArticleApi,
  deleteArticleApi,
  fetchArticleBySlugApi,
  fetchArticlesApi,
  toggleDisableArticleApi,
  updateArticleApi,
  uploadArticlePictureApi,
} from "./articleApi";

const initialState = {
  articlesLoad: false,
  articlesTotalCount: 0,
  articles: [],

  uploadPictureLoading: false,
  articleBySlugLoading: false,
  articleBySlug: null,
};

export const articleSlice = createSlice({
  name: "dashboard/article",
  initialState,
  reducers: {
    setFetchArticlesLoading(state, action) {
      state.articlesLoad = action.payload;
    },
    fetchArticlesSuccess(state, action) {
      state.articles = action.payload.articles;
      state.articlesTotalCount = action.payload.count;
    },
    setFetchArticleBySlugLoading(state, action) {
      state.articleBySlugLoading = action.payload;
    },
    fetchArticleBySlugSuccess(state, action) {
      state.articleBySlug = action.payload;
    },
    setUploadPictureLoading(state, action) {
      state.uploadPictureLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFetchArticlesLoading,
  fetchArticlesSuccess,
  fetchArticleBySlugSuccess,
  setFetchArticleBySlugLoading,
  setUploadPictureLoading,
} = articleSlice.actions;

export const createArticle = (data) => async (dispatch) => {
  const response = await createArticleApi(data);
  return response.data.data;
};

export const updateArticle =
  ({ ArticleId, ...rest }) =>
  async (dispatch) => {
    const response = await updateArticleApi(ArticleId, rest);
    return response.data.data;
  };

export const fetchArticles = (params) => async (dispatch) => {
  dispatch(setFetchArticlesLoading(true));
  try {
    const response = await fetchArticlesApi(params);
    dispatch(fetchArticlesSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchArticlesLoading(false));
};

export const fetchArticleBySlug = (slug, params = {}) => async (dispatch) => {
  dispatch(setFetchArticleBySlugLoading(true));
  dispatch(fetchArticleBySlugSuccess(null));
  try {
    const response = await fetchArticleBySlugApi(slug, params);
    dispatch(fetchArticleBySlugSuccess(response.data.data.article));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchArticleBySlugLoading(false));
};

export const toggleDisableArticle = (id) => async (dispatch) => {
  const response = await toggleDisableArticleApi(id);
  return response.data.data;
};

export const deleteArticle = (id) => async (dispatch) => {
  const response = await deleteArticleApi(id);
  return response.data.data;
};

export const createArticlePicture = (file) => async (dispatch) => {
  dispatch(setUploadPictureLoading(true));
  try {
    const response = await uploadArticlePictureApi(file);
    dispatch(setUploadPictureLoading(false));
    return Promise.resolve(response.data.data);
  } catch (err) {
    dispatch(setUploadPictureLoading(false));
    throw err;
  }
};

export default articleSlice.reducer;
