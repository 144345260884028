import { Button, Col, Dropdown, Menu, Row } from "antd";
import {
  BarsOutlined,
  SettingOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import useWindowSize from "../app/hooks/useWindowSize";
import "./DashboardLayout.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../app/constants/userRoles";
import { HashLink } from "react-router-hash-link";
import { useEffect, useMemo, useState } from "react";
import { PAGES_PLACEMENTS } from "../app/constants/constants";
import Sider from "antd/lib/layout/Sider";
import { useHistory } from "react-router-dom";
const BASE_LINK = "https://thetimesharepro.com";

const menu = [
  {
    title: "Dashboard Home",
    url: "/dashboard",
  },
  {
    title: "Manage Resorts",
    url: "/dashboard/resorts",
    role: USER_ROLES.SUPER_ADMIN,
  },
  {
    title: "Add Listing",
    url: "/dashboard/listings/service-plans",
    key: "/dashboard/listings/new",
  },
  {
    title: "Manage Listings",
    url: "/dashboard/listings",
  },
  {
    title: "Manage Bookings",
    url: "/dashboard/bookings",
  },
  {
    title: "Manage Pages",
    url: "/dashboard/page",
    role: USER_ROLES.SUPER_ADMIN,
  },
  {
    title: "Manage Blog",
    url: "/dashboard/article",
    role: USER_ROLES.SUPER_ADMIN,
  },
  {
    title: "Settings",
    url: "/dashboard/settings",
    role: USER_ROLES.SUPER_ADMIN,
  },
  {
    title: "Reports",
    url: "/dashboard/report",
    children: [
      {
        title: "Activity Summary",
        url: "/dashboard/report/activity_summary_by_year",
      },
      {
        title: "Resort Summary",
        url: "/dashboard/report/activity_summary_by_resort",
      },
      {
        title: "Listing Detail",
        url: "/dashboard/report/current_listings",
      },
    ],
  },
  {
    title: "Manage Account",
    url: "/dashboard/profile",
  },
];

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("");
  const { user } = useSelector((state) => state.auth);

  const menuItems = useMemo(() => {
    if (!user) return [];
    return menu.filter((item) => !item.role || item.role === user.Role);
  }, [user]);

  const selectedKeys = useMemo(
    () =>
      menuItems
        .filter((item) => activeKey === item.url || activeKey === item.key)
        .map((item) => item.key || item.url),
    [activeKey, menuItems]
  );

  useEffect(() => {
    setActiveKey(location.pathname);
  }, [location.pathname]);

  return (
    <div className="dashboard-layout">
      <Sider breakpoint="md" collapsedWidth="0" className="dashboard-sidebar">
        <Menu
          mode="inline"
          style={{ height: "100%", borderRight: 0 }}
          activeKey={selectedKeys.length ? selectedKeys[0] : null}
        >
          {menuItems.map((item) =>
            !item.children ? (
              <Menu.Item key={item.url}>
                <Link to={item.url}>{item.title}</Link>
              </Menu.Item>
            ) : (
              <Menu.SubMenu key={item.url} title={item.title}>
                {item.children.map((subitem) => (
                  <Menu.Item key={subitem.url}>
                    <Link to={subitem.url}>{subitem.title}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          )}
        </Menu>
      </Sider>
      <div className="dashboard-content">{children}</div>
    </div>
  );
};

export default DashboardLayout;
