import axios from "axios";
import { store } from "../store";
const AUTH_HEADER_NAME = "Authorization";

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
service.interceptors.request.use(async (config) => {
  // config.
  const token = store.getState().auth.token;

  if (token && !config.headers[AUTH_HEADER_NAME]) {
    config.headers[AUTH_HEADER_NAME] = `bearer ${token}`;
  }

  return config;
});

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      const user = store.getState().auth.user;
      if (user) {
        localStorage.setItem(
          `latest_location_${user.UserId}_${user.Role}`,
          window.location.href
        );
      }

      store.dispatch({ type: "auth/logoutUser", payload: undefined });
    } else {
      throw error;
    }
  }
);

export default service;
