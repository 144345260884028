import { createSlice } from "@reduxjs/toolkit";
import { getOwnersApi, getRefundPoliciesApi, updateOwnerApi } from "./ownerApi";

const initialState = {
  ownersSearchResults: [],
  ownersSearchLoading: false,
  updateOwnerLoading: false,
  refundPolicies: [],
};

export const ownerSlice = createSlice({
  name: "owner",
  initialState,
  reducers: {
    setOwnersSearchResults(state, action) {
      state.ownersSearchResults = action.payload.owners;
    },
    setOwnersSearchLoading(state, action) {
      state.ownersSearchLoading = action.payload;
    },
    setUpdateOwnerLoading(state, action) {
      state.updateOwnerLoading = action.payload;
    },
    setRefundPolicies(state, action) {
      state.refundPolicies = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOwnersSearchResults,
  setOwnersSearchLoading,
  setUpdateOwnerLoading,
  setRefundPolicies,
} = ownerSlice.actions;

export const searchOwners =
  ({ search, page, perPage }) =>
  async (dispatch) => {
    dispatch(setOwnersSearchLoading(true));
    try {
      console.log("Before request");
      const response = await getOwnersApi({
        search,
        page,
        perPage,
      });
      dispatch(setOwnersSearchResults(response.data.data));
    } catch (err) {
      console.log(err);
    }
    dispatch(setOwnersSearchLoading(false));
  };

export const updateOwner = (OwnerId, data) => async (dispatch) => {
  dispatch(setUpdateOwnerLoading(true));
  try {
    await updateOwnerApi(OwnerId, data);
    dispatch(setUpdateOwnerLoading(false));
  } catch (err) {
    dispatch(setUpdateOwnerLoading(false));
    throw err;
  }
};

export const fetchRefundPolicies = () => async (dispatch) => {
  try {
    const response = await getRefundPoliciesApi();
    dispatch(setRefundPolicies(response.data.data.refundPolicies))
  } catch (err) {
    console.log(err);
  }
};

export default ownerSlice.reducer;
