import qs from "querystring";
import request from "../../../app/utils/request";

export function getOwnersApi(params) {
  return request.get(`/dashboard/owner?${qs.encode(params)}`);
}

export function updateOwnerApi(OwnerId, data) {
  return request.put(`/dashboard/owner/${OwnerId}`, data);
}

export function getRefundPoliciesApi() {
  return request.get(`/dashboard/owner/refund-policy`);
}
