import { createSelector, createSlice, select } from "@reduxjs/toolkit";
// import {
//   setGlobalLoaderMessage,
//   setGlobalLoaderVisible,
// } from "../../globalSettings/globalSettingsSlice";
import {
  fetchActivitySummaryByResortApi,
  fetchActivitySummaryByYearApi,
  fetchCurrentListingsApi,
  fetchDashboardSummaryApi,
  fetchDBSummaryApi,
} from "./reportApi";

const initialState = {
  fetchCurrentListingsLoading: false,
  currentListings: [],
  currentListingsCount: 0,

  activitySummaryByYearLoading: false,
  activitySummaryByYearData: [],
  activitySummaryByYearCount: 0,

  activitySummaryByResortLoading: false,
  activitySummaryByResortData: [],
  activitySummaryByResortCount: 0,

  dashboardSummaryLoading: false,
  dashboardSummary: null,

  dbSummaryLoading: false,
  dbSummary: [],
};

export const reportSlice = createSlice({
  name: "dashboard/report",
  initialState,
  reducers: {
    setFetchCurrentListingsLoading(state, action) {
      state.fetchCurrentListingsLoading = action.payload;
    },
    fetchCurrentListingSuccess(state, action) {
      state.currentListings = action.payload.listings;
      state.currentListingsCount = action.payload.count;
    },
    fetchActivitySummaryByYearSuccess(state, action) {
      state.activitySummaryByYearData = action.payload.data;
      state.activitySummaryByYearCount = action.payload.count;
    },
    setActivitySummaryByYearLoading(state, action) {
      state.activitySummaryByYearLoading = action.payload;
    },
    fetchActivitySummaryByResortSuccess(state, action) {
      state.activitySummaryByResortData = action.payload.data;
      state.activitySummaryByResortCount = action.payload.count;
    },
    setActivitySummaryByResortLoading(state, action) {
      state.activitySummaryByResortLoading = action.payload;
    },
    setDasboardSummary(state, action) {
      state.dashboardSummary = action.payload;
    },
    setDashboardSummaryLoading(state, action) {
      state.dashboardSummaryLoading = action.payload;
    },
    setDBSummary(state, action) {
      state.dbSummary = action.payload;
    },
    setDBSummaryLoading(state, action) {
      state.dbSummaryLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchCurrentListingSuccess,
  setFetchCurrentListingsLoading,
  fetchActivitySummaryByYearSuccess,
  setActivitySummaryByYearLoading,
  fetchActivitySummaryByResortSuccess,
  setActivitySummaryByResortLoading,
  setDashboardSummaryLoading,
  setDasboardSummary,
  setDBSummary,
  setDBSummaryLoading,
} = reportSlice.actions;

export const fetchCurrentListings = (params) => async (dispatch) => {
  dispatch(setFetchCurrentListingsLoading(true));
  try {
    const response = await fetchCurrentListingsApi(params);
    dispatch(fetchCurrentListingSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchCurrentListingsLoading(false));
};

export const fetchActivitySummaryByYear = (params) => async (dispatch) => {
  dispatch(setActivitySummaryByYearLoading(true));
  try {
    const response = await fetchActivitySummaryByYearApi(params);
    dispatch(fetchActivitySummaryByYearSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setActivitySummaryByYearLoading(false));
};

export const fetchActivitySummaryByResort = (params) => async (dispatch) => {
  dispatch(setActivitySummaryByResortLoading(true));
  try {
    const response = await fetchActivitySummaryByResortApi(params);
    dispatch(fetchActivitySummaryByResortSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setActivitySummaryByResortLoading(false));
};

export const fetchDashboardSummary = (params) => async (dispatch) => {
  dispatch(setDashboardSummaryLoading(true));
  try {
    const response = await fetchDashboardSummaryApi(params);
    dispatch(setDasboardSummary(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setDashboardSummaryLoading(false));
};

export const fetchDBSummary = (params) => async (dispatch) => {
  dispatch(setDBSummaryLoading(true));
  try {
    const response = await fetchDBSummaryApi(params);
    dispatch(setDBSummary(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setDBSummaryLoading(false));
};

export default reportSlice.reducer;
