import qs from "querystring";
import request from "../../../app/utils/request";

export function fetchBookingsApi(params) {
  return request.get(`/dashboard/booking?${qs.encode(params)}`);
}

export function fetchBookingByIdApi(id) {
  return request.get(`/dashboard/booking/${id}`);
}

export function approveBookingApi(id) {
  return request.post(`/dashboard/booking/${id}/approve`);
}

export function rejectBookingApi(id, data) {
  return request.post(`/dashboard/booking/${id}/reject`, data);
}