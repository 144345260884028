import { createSlice } from "@reduxjs/toolkit";
import { fetchResortListingAPI } from "../resort/resortApi";
import {
  fetchBookingStatusAPI,
  initBookingAPI,
  verifyCouponAPI,
} from "./bookingApi";

const initialState = {
  value: 0,
  loading: false,
  err: null,
  status: "",
  receiptUrl: "",
  paidAmount: 0,
  booking: null,
  paymentType: "",
  listing: null,

  initLoading: false,
  initErr: null,
  initClientSecret: "",

  confirmBookingLoading: false,
  confirmBookingErr: "",
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    fetchBookingStatusLoading(state) {
      state.loading = true;
      state.err = null;
    },
    fetchBookingStatusSuccess(state, action) {
      state.loading = false;
      state.err = null;
      state.paymentType = action.payload.paymentType;
      state.paidAmount = action.payload.paidAmount;
      state.status = action.payload.status;
      state.booking = action.payload.booking;
      state.receiptUrl = action.payload.receiptUrl;
      state.listing = action.payload.listing;
    },
    fetchBookingStatusFailed(state, action) {
      state.loading = false;
      state.err = action.payload;
    },
    initBookingLoading(state) {
      state.initLoading = true;
      state.initErr = null;
    },
    initBookingFailed(state, action) {
      state.initLoading = false;
      state.initErr = action.payload;
    },
    initBookingSuccess(state, action) {
      state.initLoading = false;
      state.initClientSecret = action.payload.clientSecret;
    },
    confirmBookingLoading(state) {
      state.confirmBookingErr = "";
      state.confirmBookingLoading = true;
    },
    confirmBookingFailed(state, action) {
      state.confirmBookingErr = action.payload;
      state.confirmBookingLoading = false;
    },
    confirmBookingSuccess(state, action) {
      state.confirmBookingErr = "";
      state.confirmBookingLoading = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchBookingStatusFailed,
  fetchBookingStatusLoading,
  fetchBookingStatusSuccess,
  initBookingFailed,
  initBookingLoading,
  initBookingSuccess,
  confirmBookingFailed,
  confirmBookingLoading,
  confirmBookingSuccess,
} = bookingSlice.actions;

export const fetchBookingStatus = (params) => async (dispatch) => {
  dispatch(fetchBookingStatusLoading());
  try {
    const response = await fetchBookingStatusAPI(params);
    dispatch(fetchBookingStatusSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    dispatch(fetchBookingStatusFailed(err));
  }
};

export const initBooking =
  ({
    email,
    firstName,
    lastName,
    phone,
    paymentType,
    comments,
    address,
    city,
    state,
    zipCode,
    country,
    listingId,
    captchaToken,
    coupon,
  }) =>
  async (dispatch) => {
    dispatch(initBookingLoading());
    try {
      const response = await initBookingAPI({
        email,
        firstName,
        lastName,
        phone,
        paymentType,
        comments,
        address,
        city,
        state,
        zipCode,
        country,
        listingId,
        captchaToken,
        coupon,
      });
      await dispatch(initBookingSuccess(response.data.data));
    } catch (err) {
      console.log(err);
      dispatch(initBookingFailed(err));
      throw err;
    }
  };

export const confirmBooking =
  ({ stripe, listingId, elements, return_url }) =>
  async (dispatch) => {
    dispatch(confirmBookingLoading());

    try {
      const response = await fetchResortListingAPI(listingId);
      const listing = response.data.data;
      console.log({ listing });
      if (listing.ListingStatus !== "Available") {
        return dispatch(
          confirmBookingFailed(
            "This listing has been already booked or currently unavailable"
          )
        );
      }
    } catch (err) {
      return dispatch(confirmBookingFailed("Something went wrong"));
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url,
      },
    });
    if (error) {
      return dispatch(confirmBookingFailed(error.message));
    }

    dispatch(confirmBookingSuccess());
  };

export const verifyCoupon =
  ({ coupon, listingId, paymentType }) =>
  async () => {
    const response = await verifyCouponAPI({ coupon, listingId, paymentType });

    return response.data.data;
  };

export default bookingSlice.reducer;
