import qs from "querystring";
import request from "../../../app/utils/request";

export function fetchArticlesApi(params) {
  return request.get(`/dashboard/article?${qs.encode(params)}`);
}

export function fetchArticleBySlugApi(slug, params) {
  return request.get(`/dashboard/article/by_slug/${slug}?${qs.encode(params)}`);
}

export function createArticleApi(data) {
  return request.post("/dashboard/article", data);
}

export function updateArticleApi(id, data) {
  return request.put(`/dashboard/article/${id}`, data);
}

export function toggleDisableArticleApi(id) {
  return request.post(`/dashboard/article/${id}/toggle-disabled`);
}

export function deleteArticleApi(id) {
  return request.delete(`/dashboard/article/${id}`);
}

export function uploadArticlePictureApi(picture) {
  const data = new FormData();
  data.append("picture", picture);

  return request.post(`/dashboard/article/picture`, data, {
    headers: { "content-type": "application/form-data" },
  });
}
