import { createSelector, createSlice, select } from "@reduxjs/toolkit";
import {
  setGlobalLoaderMessage,
  setGlobalLoaderVisible,
} from "../../globalSettings/globalSettingsSlice";
import { fetchDashboardSummaryApi } from "./summaryApi";

const initialState = {
  summaryLoading: false,
  summaryByYear: null,
};

export const summarySlice = createSlice({
  name: "dashboard/summary",
  initialState,
  reducers: {
    setSummaryLoading(state, action) {
      state.summaryLoading = action.payload;
    },
    setSummaryByYear(state, action) {
      state.summaryByYear = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSummaryByYear, setSummaryLoading } = summarySlice.actions;

export const fetchSummaryByYear = (params) => async (dispatch) => {
  dispatch(setSummaryLoading(true));
  try {
    const response = await fetchDashboardSummaryApi(params);
    dispatch(setSummaryByYear(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setSummaryLoading(false));
};

export default summarySlice.reducer;
