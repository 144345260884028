import { createSelector, createSlice, select } from "@reduxjs/toolkit";
import {
  approveBookingApi,
  fetchBookingByIdApi,
  fetchBookingsApi,
  rejectBookingApi,
} from "./bookingApi";

const initialState = {
  fetchBookingsLoading: false,
  bookings: [],
  bookingById: null,
  bookingByIdLoading: false,
  bookingsCount: 0,
};

export const bookingSlice = createSlice({
  name: "dashboard/bookings",
  initialState,
  reducers: {
    setFetchBookingsLoading(state, action) {
      state.fetchBookingsLoading = action.payload;
    },
    setBookings(state, action) {
      state.bookings = action.payload.bookings;
      state.bookingsCount = action.payload.count;
    },
    fetchBookingByIdLoading(state, action) {
      state.bookingByIdLoading = true;
    },
    fetchBookingByIdSuccess(state, action) {
      state.bookingByIdLoading = false;
      state.bookingById = action.payload;
    },
    fetchBookingByIdFailed(state) {
      state.bookingByIdLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFetchBookingsLoading,
  setBookings,
  confirmBookingSuccess,
  fetchBookingByIdLoading,
  fetchBookingByIdSuccess,
  fetchBookingByIdFailed,
} = bookingSlice.actions;

export const fetchBookings = (params) => async (dispatch) => {
  dispatch(setFetchBookingsLoading(true));
  try {
    const response = await fetchBookingsApi(params);
    dispatch(setBookings(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchBookingsLoading(false));
};

export const fetchBookingById = (id) => async (dispatch) => {
  dispatch(fetchBookingByIdLoading());
  try {
    const response = await fetchBookingByIdApi(id);
    dispatch(fetchBookingByIdSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    dispatch(fetchBookingByIdFailed());
  }
};

export const approveBooking = (id) => async (dispatch) => {
  const response = await approveBookingApi(id);
  dispatch(fetchBookingByIdSuccess(response.data.data));
};

export const rejectBooking = (id, data) => async (dispatch) => {
  const response = await rejectBookingApi(id, data);
  dispatch(fetchBookingByIdSuccess(response.data.data));
};


export default bookingSlice.reducer;
