import { createSelector, createSlice, select } from "@reduxjs/toolkit";
import {
  setGlobalLoaderMessage,
  setGlobalLoaderVisible,
} from "../../globalSettings/globalSettingsSlice";
import {
  createListingApi,
  deleteListingApi,
  fetchListingByIdApi,
  fetchListingPaymentStatusApi,
  fetchListingsApi,
  initCreateListingApi,
  searchListingsApi,
  toggleListingVerifiedApi,
  updateListingApi,
} from "./listingApi";

const initialState = {
  value: 0,
  fetchListingsLoading: false,
  listings: [],
  listingsCount: 0,
  createListingLoading: false,
  updateListingLoading: false,
  listing: null,
  searchListingsLoading: false,
  searchListingsResults: [],

  confirmListingLoading: false,
  confirmListingErr: "",
  newListingData: {
    listing: null,
    status: "",
    receiptUrl: "",
  },
};

export const listingSlice = createSlice({
  name: "dashboard/listing",
  initialState,
  reducers: {
    setFetchListingsLoading(state, action) {
      state.fetchListingsLoading = action.payload;
    },
    fetchListingSuccess(state, action) {
      state.listings = action.payload.listings;
      state.listingsCount = action.payload.count;
    },
    setCreateListingLoading(state, action) {
      state.createListingLoading = action.payload;
    },
    fetchListingByIdSuccess(state, action) {
      state.listing = action.payload;
    },
    setUpdateListingLoading(state, action) {
      state.updateListingLoading = action.payload;
    },
    setSearchLoading(state, action) {
      state.searchListingsLoading = action.payload;
    },
    setSearchResults(state, action) {
      state.searchListingsResults = action.payload;
    },
    confirmListingLoading(state) {
      state.confirmListingErr = "";
      state.confirmListingLoading = true;
    },
    confirmListingFailed(state, action) {
      state.confirmListingErr = action.payload;
      state.confirmListingLoading = false;
    },
    confirmListingSuccess(state, action) {
      state.confirmListingErr = "";
      state.confirmListingLoading = true;
    },
    fetchListingPaymentStatusSuccess(state, action) {
      state.loading = false;
      state.err = null;
      const newListing = {
        listing: action.payload.listing,
        receiptUrl: action.payload.receiptUrl,
        status: action.payload.status,
      };
      state.newListingData = newListing;
    },
    fetchListingPaymentStatusLoading(state, action) {
      // state.newListingData = initialState.newListingData;
    },
    fetchListingPaymentStatusFailed(state) {
      state.newListingData = initialState.newListingData;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchListingSuccess,
  fetchListingByIdSuccess,
  setFetchListingsLoading,
  setCreateListingLoading,
  setUpdateListingLoading,
  confirmListingFailed,
  confirmListingLoading,
  confirmListingSuccess,
  fetchListingPaymentStatusSuccess,
  fetchListingPaymentStatusLoading,
  fetchListingPaymentStatusFailed,
  setSearchLoading,
  setSearchResults,
} = listingSlice.actions;

export const searchListings = (params) => async (dispatch) => {
  dispatch(setSearchLoading(true));
  dispatch(setSearchResults([]));
  try {
    const response = await searchListingsApi(params);
    dispatch(setSearchResults(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setSearchLoading(false));
};

export const fetchListings = (params) => async (dispatch) => {
  dispatch(setFetchListingsLoading(true));
  try {
    const response = await fetchListingsApi(params);
    dispatch(fetchListingSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchListingsLoading(false));
};

export const createListing = (data) => async (dispatch) => {
  dispatch(setCreateListingLoading(true));
  try {
    const response = await createListingApi(data);
    dispatch(setCreateListingLoading(false));
    return Promise.resolve(response.data.data.listing);
  } catch (err) {
    dispatch(setCreateListingLoading(false));
    throw err;
  }
};

export const initCreateListing = (data) => async (dispatch) => {
  dispatch(setCreateListingLoading(true));
  try {
    const response = await initCreateListingApi(data);
    dispatch(setCreateListingLoading(false));
    return Promise.resolve(response.data.data);
  } catch (err) {
    dispatch(setCreateListingLoading(false));
    throw err;
  }
};

export const confirmListing =
  ({ stripe, elements, return_url }) =>
  async (dispatch) => {
    dispatch(confirmListingLoading());

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url,
      },
    });
    if (error) {
      return dispatch(confirmListingFailed(error.message));
    }

    dispatch(confirmListingSuccess());
  };

export const fetchListingPaymentStatus = (params) => async (dispatch) => {
  dispatch(fetchListingPaymentStatusLoading());
  try {
    const response = await fetchListingPaymentStatusApi(params);
    dispatch(fetchListingPaymentStatusSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    dispatch(fetchListingPaymentStatusFailed(err));
  }
};

export const updateListing = (id, data) => async (dispatch) => {
  dispatch(setUpdateListingLoading(true));
  try {
    const response = await updateListingApi(id, data);
    dispatch(setUpdateListingLoading(false));
    return Promise.resolve(response.data.data.listing);
  } catch (err) {
    dispatch(setUpdateListingLoading(false));
    throw err;
  }
};

export const fetchListingById = (id) => async (dispatch) => {
  dispatch(setFetchListingsLoading(true));
  try {
    const response = await fetchListingByIdApi(id);
    dispatch(fetchListingByIdSuccess(response.data.data.listing));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchListingsLoading(false));
};

export const toggleListingVerified = (id) => async (dispatch) => {
  dispatch(setGlobalLoaderVisible(true));
  dispatch(
    setGlobalLoaderMessage("Please be patient as this may take a few minutes.")
  );
  try {
    const res = await toggleListingVerifiedApi(id);
    dispatch(setGlobalLoaderVisible(false));
    return res;
  } catch (err) {
    console.log(err);
    dispatch(setGlobalLoaderVisible(false));
    throw err;
  }
};

export const deleteListing = (id, data) => async (dispatch) => {
  dispatch(setFetchListingsLoading(true));
  try {
    await deleteListingApi(id, data);
    dispatch(setFetchListingsLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(setFetchListingsLoading(false));
    throw err;
  }
};

export default listingSlice.reducer;
