import { createSlice } from "@reduxjs/toolkit";
import {
  fetchResortAddressLongLatAPI,
  fetchResortAPI,
  fetchResortListingAPI,
  inquireListingAPI,
  fetchTopResortsAPI,
  searchResortsApi,
  fetchTopDestinatiosAPI,
  fetchResortViewsApi,
} from "./resortApi";

const initialState = {
  value: 0,
  resort: null,
  resortListingCount: 0,
  loading: false,
  err: null,

  listing: null,
  listingLoading: false,
  listingErr: null,

  inquireListingLoading: false,
  resortAddress: {
    address: "",
    lng: "",
    lat: "",
  },
  topResorts: [],
  topDestinations: [],
  resortsSearchResults: [],
  resortsSearchLoading: false,
  resortViews: [],
};

export const resortSlice = createSlice({
  name: "resort",
  initialState,
  reducers: {
    fetchResortLoading(state) {
      state.loading = true;
      state.err = null;
    },
    fetchResortSuccess(state, action) {
      state.loading = false;
      state.err = null;
      state.resort = action.payload.resort;
      state.resortListingCount = action.payload.count;
    },
    fetchResortFailed(state, action) {
      state.loading = false;
      state.err = action.payload;
    },
    fetchListingLoading(state) {
      state.listingLoading = true;
      state.listingErr = null;
    },
    fetchListingSuccess(state, action) {
      state.listing = action.payload;
      state.listingLoading = false;
      state.listingErr = null;
    },
    fetchListingFailed(state, action) {
      state.listingLoading = false;
      state.listingErr = action.payload;
    },
    inquireListingLoading(state) {
      state.inquireListingLoading = true;
    },
    inquireListingSuccess(state) {
      state.inquireListingLoading = false;
    },
    inquireListingFailed(state) {
      state.inquireListingLoading = false;
    },
    setResortAddress(state, action) {
      state.resortAddress = action.payload;
    },
    setTopResorts(state, action) {
      console.log({ action });
      state.topResorts = action.payload;
    },
    setResortsSearchResults(state, action) {
      state.resortsSearchResults = action.payload;
    },
    setResortsSearchLoading(state, action) {
      state.resortsSearchLoading = action.payload;
      console.log({ loading: action.payload });
    },
    setTopDestinations(state, action) {
      state.topDestinations = action.payload;
    },
    setResortViews(state, action) {
      state.resortViews = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  inquireListingFailed,
  inquireListingLoading,
  inquireListingSuccess,
  fetchResortLoading,
  fetchResortSuccess,
  fetchResortFailed,
  fetchListingLoading,
  fetchListingSuccess,
  fetchListingFailed,
  setResortAddress,
  setTopResorts,
  setResortsSearchResults,
  setResortsSearchLoading,
  setTopDestinations,
  setResortViews,
} = resortSlice.actions;

export const fetchResort = (resortId, params) => async (dispatch) => {
  dispatch(fetchResortLoading());
  try {
    const response = await fetchResortAPI(resortId, params);
    dispatch(fetchResortSuccess(response.data.data));
  } catch (err) {
    console.log(err);
    dispatch(fetchResortFailed(err));
  }
};

export const fetchResortListing = (listingId) => async (dispatch) => {
  dispatch(fetchListingLoading());
  try {
    const response = await fetchResortListingAPI(listingId);
    dispatch(fetchListingSuccess(response.data.data));
  } catch (err) {
    dispatch(fetchListingFailed(err));
  }
};

export const inquireListing = (listingId, data) => async (dispatch) => {
  dispatch(inquireListingLoading());
  try {
    await inquireListingAPI(listingId, data);
    dispatch(inquireListingSuccess());
  } catch (err) {
    dispatch(inquireListingFailed());
    throw err;
  }
};

export const fetchResortAddressLongLat = (address) => async (dispatch) => {
  const data = await fetchResortAddressLongLatAPI(address);
  if (data.data && data.data.results && data.data.results.length) {
    const { formatted_address, geometry } = data.data.results[0];
    console.log({ formatted_address, geometry });
    dispatch(
      setResortAddress({
        address: formatted_address,
        lng: geometry.location.lng,
        lat: geometry.location.lat,
      })
    );
  }
};

export const fetchTopResorts = () => async (dispatch) => {
  try {
    const response = await fetchTopResortsAPI();
    dispatch(setTopResorts(response.data.data.resorts));
  } catch (err) {
    console.log(err);
  }
};

export const fetchTopDestinations = () => async (dispatch) => {
  try {
    const response = await fetchTopDestinatiosAPI();
    dispatch(setTopDestinations(response.data.data.resorts));
  } catch (err) {
    console.log(err);
  }
};

export const searchResorts =
  ({ search, page, perPage, source = 'active' }) =>
  async (dispatch) => {
    dispatch(setResortsSearchLoading(true));
    try {
      console.log("Before request");
      const response = await searchResortsApi({
        search,
        page,
        perPage,
        source
      });
      dispatch(setResortsSearchResults(response.data.data));
    } catch (err) {
      console.log(err);
    }
    dispatch(setResortsSearchLoading(false));
  };

export const fetchResortViews = () => async (dispatch) => {
  try {
    const response = await fetchResortViewsApi();
    dispatch(setResortViews(response.data.data.resortViews));
  } catch (err) {
    console.log(err);
  }
};

export default resortSlice.reducer;
