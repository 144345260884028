import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  notification,
  Typography,
} from "antd";
import {
  BarsOutlined,
  SettingOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import useWindowSize from "../app/hooks/useWindowSize";
import "./HomeLayout.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "../app/constants/userRoles";
import { getMe, logoutUser } from "../features/auth/authSlice";
import { HashLink } from "react-router-hash-link";
import { useEffect, useMemo } from "react";
import { FIXED_BANNER_ID, PAGES_PLACEMENTS } from "../app/constants/constants";
import { fetchSettings } from "../features/dashboard/settings/settingsSlice";
const BASE_LINK = "https://thetimesharepro.com";

const HomeLayout = ({ children, disabledRoutes = [] }) => {
  const { user } = useSelector((state) => state.auth);
  const { pages } = useSelector((state) => state.dashboardPage);
  const { banners } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1482;
  const { Title } = Typography;

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  useEffect(() => {
    if (!banners.length) return;

    const bannersData = banners.filter(
      (banner) => banner.id !== FIXED_BANNER_ID
    );
    const savedBannerData = localStorage.getItem("bannerData");
    const savedBanners = savedBannerData ? JSON.parse(savedBannerData) : [];

    bannersData.forEach((bannerData) => {
      const savedBanner = savedBanners.find(
        (saved) => saved.id === bannerData.id
      );

      if (
        !savedBanner ||
        JSON.stringify(savedBanner) !== JSON.stringify(bannerData)
      ) {
        if (bannerData.isVisible) {
          notification.open({
            message: bannerData.text,
            duration: 10,
          });
        }
      }
    });

    localStorage.setItem("bannerData", JSON.stringify(bannersData));
  }, [banners]);

  const pagesMap = useMemo(() => {
    const map = {};
    Object.keys(PAGES_PLACEMENTS).forEach((key) => {
      map[PAGES_PLACEMENTS[key]] = pages
        .filter(
          (page) => !page.Disabled && page.Placement === PAGES_PLACEMENTS[key]
        )
        .map((item) => ({
          title: item.Name,
          link: `/${item.Slug}`,
        }));
    });
    return map;
  }, [pages]);

  const menuItems = useMemo(
    () =>
      [
        {
          title: "HOME",
          link: "/",
          children: pagesMap[PAGES_PLACEMENTS.UNDER_HOME],
        },
        {
          title: "LEARN MORE",
          link: "/#learn-more",
          children: pagesMap[PAGES_PLACEMENTS.UNDER_LEARN_MORE],
        },
        {
          title: "TOP DESTINATIONS",
          link: "/#top-destinations",
          children: pagesMap[PAGES_PLACEMENTS.UNDER_TOP_DESTINATIONS],
        },
        {
          title: "CONTACT US",
          link: "/#contact-us",
          children: pagesMap[PAGES_PLACEMENTS.UNDER_CONTACT_US],
        },
        {
          title: "Blog",
          link: "/blog",
        },
        {
          title: "Help",
          extLink: "https://kb.thetimesharepro.com",
        },
      ].concat(pagesMap[PAGES_PLACEMENTS.HEADER_ITEM]),
    [pagesMap]
  );

  const onLogout = () => {
    dispatch(logoutUser());
    navigate("/");
  };

  const renderItem = (item) => {
    if (item.link) {
      return <HashLink to={`${item.link}`}>{item.title}</HashLink>;
    }
    if (item.extLink) {
      return (
        <a href={`${item.extLink}`} target="_blank">
          {item.title}
        </a>
      );
    }
    return null;
  };

  const desktopMenu = (
    <div className="menu">
      {menuItems.map((item, index) => (
        <div key={`menu-item-${item.title}`}>
          {item.children && item.children.length ? (
            <Dropdown
              overlay={
                <Menu
                  items={item.children.map((child) => ({
                    key: `${item.title}-${child.title}`,
                    label: renderItem(child),
                  }))}
                />
              }
            >
              <a onClick={(e) => e.preventDefault()}>{item.title}</a>
            </Dropdown>
          ) : (
            renderItem(item)
          )}
        </div>
      ))}
    </div>
  );

  const mobileMenu = (
    <Dropdown
      overlay={
        <Menu mode="inline">
          {menuItems.map((item) =>
            // item.children ? (
            // <Menu.SubMenu key={`group-${item.title}`} title={item.title}>
            //   {item.children.map((child) => (
            //     <Menu.Item key={`${item.title}-${child.title}`}>
            //       {renderItem(child)}
            //     </Menu.Item>
            //   ))}
            // </Menu.SubMenu>
            // ) : (
            //   <Menu.Item key={item.title}>{renderItem(item)}</Menu.Item>
            // )
            <div key={`menu-item-${item.title}`}>
              {item.children && item.children.length ? (
                <div>
                  <Dropdown
                    overlay={
                      <Menu
                        items={item.children.map((child) => ({
                          key: `${item.title}-${child.title}`,
                          label: renderItem(child),
                        }))}
                      />
                    }
                  >
                    <a onClick={(e) => e.preventDefault()}>{item.title}</a>
                  </Dropdown>
                  <Menu.SubMenu key={`group-${item.title}`} title={item.title}>
                    {item.children.map((child) => (
                      <Menu.Item key={`${item.title}-${child.title}`}>
                        {renderItem(child)}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                </div>
              ) : (
                <Menu.Item key={item.title}>{renderItem(item)}</Menu.Item>
              )}
            </div>
          )}
        </Menu>
      }
    >
      <div
        style={{
          margin: 10,
          padding: 5,
          color: "white",
          backgroundColor: "black",
        }}
      >
        <BarsOutlined style={{ fontSize: 20 }} />
      </div>
    </Dropdown>
  );
  useEffect(() => {
    if (user) {
      const interval = setInterval(() => {
        dispatch(getMe());
      }, 30 * 1000);
      return () => clearInterval(interval);
    }
  }, [user]);

  if (disabledRoutes.includes(location?.pathname)) {
    return children;
  }

  const fixedBannerData =
    banners.find((banner) => banner.id === FIXED_BANNER_ID) || undefined;

  return (
    <div className="App">
      <header className="header">
        <div className="content">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={5}>
              <Link to="/">
                <img src={"/logo.png"} className="logo" alt="logo" />
              </Link>
            </Col>
            <Col xs={24} sm={24} md={14} className="menumiddle_manu">
              {isMobile ? mobileMenu : desktopMenu}
            </Col>
            <Col xs={24} sm={24} md={5}>
              {user ? (
                <Dropdown
                  overlay={
                    <Menu
                      mode="inline"
                      title={
                        user.Owner
                          ? `${user.Owner.OwnerFName} ${user.Owner.OwnerLName}`
                          : user.email
                      }
                    >
                      <Menu.Item>
                        <Link to="/dashboard">Dashboard</Link>
                      </Menu.Item>
                      {user.Role === USER_ROLES.OWNER && (
                        <Menu.Item>
                          <Link to="/dashboard/profile">Account</Link>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <Link to="/dashboard/listings">Listings</Link>
                      </Menu.Item>
                      {user.Role === USER_ROLES.SUPER_ADMIN && (
                        <Menu.Item>
                          <Link to="/dashboard/resorts">Resorts</Link>
                        </Menu.Item>
                      )}
                      {user.Role === USER_ROLES.SUPER_ADMIN && (
                        <Menu.Item>
                          <Link to="/dashboard/page">Pages</Link>
                        </Menu.Item>
                      )}
                      {user.Role === USER_ROLES.SUPER_ADMIN && (
                        <Menu.Item>
                          <Link to="/dashboard/article">Blog</Link>
                        </Menu.Item>
                      )}
                      <Menu.Divider />
                      <Menu.ItemGroup title="Reports">
                        <Menu.Item>
                          <Link to="/dashboard/report/current_listings">
                            Listing Detail
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/dashboard/report/activity_summary_by_year">
                            Activity Summary
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link to="/dashboard/report/activity_summary_by_resort">
                            Resort Summary
                          </Link>
                        </Menu.Item>
                      </Menu.ItemGroup>
                      <Menu.Divider />
                      <Menu.Item onClick={onLogout}>Logout</Menu.Item>
                    </Menu>
                  }
                >
                  <div>
                    {user.Owner
                      ? `${user.Owner.OwnerFName} ${user.Owner.OwnerLName}`
                      : user.Email}{" "}
                    <CaretDownOutlined style={{ marginLeft: 10 }} />
                  </div>
                </Dropdown>
              ) : (<>
                <Link to="/dashboard/auth/register/owner">
                  <Button className="call-us-btn2">
                    Become a Member
                  </Button>
                </Link>
                <Link to="/dashboard/auth/login">
                  <Button type="primary" className="call-us-btn">
                    SIGN IN
                  </Button>
                </Link>
              </>)}
            </Col>
          </Row>
        </div>
      </header>
      {fixedBannerData?.isVisible && (
        <div
          style={{
            backgroundColor: "#ff7f47",
            padding: "1px 20px",
            // marginTop: "-25px",
            width: "100%",
          }}
        >
          {
            <Title
              className="reg_titel"
              style={{
                textAlign: "center",
                margin: "20px 0",
                fontSize: "1.5em",
                color: "white",
              }}
            >
              {banners.find((banner) => banner.id === FIXED_BANNER_ID).text}
            </Title>
          }
        </div>
      )}
      <div className="body">{children}</div>
      <footer>
        {pagesMap[PAGES_PLACEMENTS.FOOTER].length ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {pagesMap[PAGES_PLACEMENTS.FOOTER].map((page) => (
              <Link to={page.link} key={`custom-page-${page.link}`}>
                <a
                  style={{
                    color: "#ff7f47",
                    textDecoration: "underline",
                    paddingLeft: 10,
                  }}
                >
                  {page.title}
                </a>
              </Link>
            ))}
          </div>
        ) : null}
        <div>
          {new Date().getFullYear()} The Timeshare Pro. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};
export default HomeLayout;
