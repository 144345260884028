export const PAGES_PLACEMENTS = {
  FOOTER: "footer",
  HEADER_ITEM: "header_item",
  UNDER_HOME: "under_home",
  UNDER_LEARN_MORE: "under_learn_more",
  UNDER_TOP_DESTINATIONS: "under_top_destinations",
  UNDER_CONTACT_US: "under_contact_us",
};

export const PAGES_PLACEMENTS_OPTIONS = [
  {
    label: "Footer",
    value: PAGES_PLACEMENTS.FOOTER,
  },
  {
    label: "Header Item",
    value: PAGES_PLACEMENTS.HEADER_ITEM,
  },
  {
    label: "Under Home",
    value: PAGES_PLACEMENTS.UNDER_HOME,
  },
  {
    label: "Under Learn More",
    value: PAGES_PLACEMENTS.UNDER_LEARN_MORE,
  },
  {
    label: "Under Top Destinations",
    value: PAGES_PLACEMENTS.UNDER_TOP_DESTINATIONS,
  },
  {
    label: "Under Contact Us",
    value: PAGES_PLACEMENTS.UNDER_CONTACT_US,
  },
];

export const ARTICLE_IMAGE_PLACEMENTS = {
  TOP: "top",
  RIGHT: "right",
  LEFT: "left",
  BOTTOM: "bottom",
};

export const ARTICLE_IMAGE_PLACEMENT_OPTION = [
  {
    label: "Top",
    value: ARTICLE_IMAGE_PLACEMENTS.TOP,
  },
  {
    label: "Bottom",
    value: ARTICLE_IMAGE_PLACEMENTS.BOTTOM,
  },
  {
    label: "Right",
    value: ARTICLE_IMAGE_PLACEMENTS.RIGHT,
  },
  {
    label: "Left",
    value: ARTICLE_IMAGE_PLACEMENTS.LEFT,
  },
];

export const BLOG_PLACEHOLDER_IMAEG_URL =
  "https://demo.yootheme.com/joomla/templates/yootheme/cache/99/news-post-placeholder-995d1b5d.jpeg";

export const MAX_IMAGE_SIZE_MB = 10;

export const LISTING_TYPES = {
  SELF_SERVICE: 1,
  FULL_SERVICE: 2,
};

export const TERMS_LISTING_TYPE_MAP = {
  [LISTING_TYPES.FULL_SERVICE]:
    "As the Timeshare owner, once a guest selects your Timeshare to rent and pays via TheTimesharePro payment portal, you will receive a text verification allowing you the option to accept or decline any reservation request. If you accept the reservation request, TheTimeSharePro.com will make sure the reservation is transferred to the guest. You will receive your rental funds within 5-10 days of the reservation transfer date.",
  [LISTING_TYPES.SELF_SERVICE]:
    "The guest will be able to request a booking directly from the owner. The Owner will be responsible for collecting the payment and reservation transfer to the renter.",
};

export const FIXED_BANNER_ID = 3;
