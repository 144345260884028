import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getMe } from "../../features/auth/authSlice";
import DashboardLayout from "../../Layouts/DashboardLayout";

const IsAuthenticated = (props = {}) => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { component: Children, ...rest } = props;

  useEffect(() => {
    dispatch(getMe());
  }, []);

  if (!token) {
    return <Navigate to="/dashboard/auth/login" />;
  }

  return (
    <DashboardLayout>
      <Children {...rest} />
    </DashboardLayout>
  );
};
export default IsAuthenticated;
