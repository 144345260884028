import { createSelector, createSlice, select } from "@reduxjs/toolkit";
import {
  getBannersApi,
  isFreeListingEnabled,
  updateIsFreeListingEnabled,
} from "./settingsApi";

const initialState = {
  isLoading: false,
  isFreeListingEnabled: false,
  isUserEligibleForFreeListing: false,
  banners: [],
};

export const settingsSlice = createSlice({
  name: "dashboard/settings",
  initialState,
  reducers: {
    setSettingsIsLoading(state, action) {
      state.isLoading = action.payload.isLoading;
    },
    setIsFreeListingEnabled(state, action) {
      state.isFreeListingEnabled = action.payload.isFreeListingEnabled;
    },
    setIsUserEligibleForFreeListing(state, action) {
      state.isUserEligibleForFreeListing =
        action.payload.isUserEligibleForFreeListing;
    },
    setBanners(state, action) {
      state.banners = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSettingsIsLoading,
  setIsFreeListingEnabled,
  setBanners,
  setIsUserEligibleForFreeListing,
} = settingsSlice.actions;

export const fetchSettings = () => async (dispatch) => {
  dispatch(setSettingsIsLoading(true));
  try {
    const response = await isFreeListingEnabled();
    const banners = await getBannersApi();
    dispatch(setBanners(banners.data.data.banners));
    dispatch(setIsFreeListingEnabled(response.data.data));
    dispatch(setIsUserEligibleForFreeListing(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setSettingsIsLoading(false));
};

export const updateSettings = (value) => async (dispatch) => {
  dispatch(setSettingsIsLoading(true));
  try {
    await updateIsFreeListingEnabled(value);
    dispatch(
      setIsFreeListingEnabled({
        isFreeListingEnabled: value,
      })
    );
  } catch (err) {
    console.log(err);
  }
  dispatch(setSettingsIsLoading(false));
};

export default settingsSlice.reducer;
