import request from "../../app/utils/request";

export function loginUserAPI(data) {
  return request.post("/auth/login", data);
}

export function getMeApi() {
  return request.get("/auth/me");
}

export function requestResetPasswordApi(data) {
  return request.post("/auth/reset-password/request", data);
}

export function submitResetPasswordApi({ password, token }) {
  return request.post(
    "/auth/reset-password/submit",
    { password },
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

export function initOwnerRegistrationApi(data) {
  return request.post("/auth/register/owner/init", data);
}

export function verifyOwnerRegistrationTokenApi(token) {
  return request.get("/auth/register/owner/verify", {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function submitOwnerRegistrationApi(data, token) {
  return request.post("/auth/register/owner/submit", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function registerOwnerApi(data) {
  return request.post("/auth/register/owner", data);
}
