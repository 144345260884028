import { createSlice } from "@reduxjs/toolkit";
import {
  getMeApi,
  initOwnerRegistrationApi,
  loginUserAPI,
  registerOwnerApi,
  requestResetPasswordApi,
  submitContactAPI,
  submitOwnerRegistrationApi,
  submitResetPasswordApi,
  verifyOwnerRegistrationTokenApi,
} from "./authApi";

const initialToken = localStorage.getItem("token") || "";
let initialUser = null;

if (localStorage.getItem("user")) {
  try {
    initialUser = JSON.parse(localStorage.getItem("user"));
  } catch (err) {}
}

const initialState = {
  loginLoading: false,
  token: initialToken,
  user: initialUser,
  getMeLoading: false,
  resetPasswordLoading: false,
  resetPasswordSubmitted: false,
  ownerRegistrationLoading: false,
  ownerRegistrationSubmitted: false,
  ownerRegistrationData: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginLoading(state, action) {
      state.loginLoading = action.payload;
    },
    setLoginData(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.user;
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
    },
    loginSuccess(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.user;
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));

    },
    logoutUser(state) {
      const latestLocationKey = `latest_location_${state.user.UserId}_${state.user.Role}`;

      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem(latestLocationKey);

      state.token = "";
      state.user = null;
    },
    setGetMeLoading(state, action) {
      state.getMeLoading = action.payload;
    },
    getMeSuccess(state, action) {
      state.user = action.payload.user;
      localStorage.setItem("user", JSON.stringify(action.payload.user));
    },
    setResetPasswordLoading(state, action) {
      state.resetPasswordLoading = action.payload;
    },
    setResetPasswordSubmitted(state, action) {
      state.resetPasswordSubmitted = action.payload;
    },
    setOwnerRegistrationLoading(state, action) {
      state.ownerRegistrationLoading = action.payload;
    },
    setOwnerRegistrationSubmitted(state, action) {
      state.ownerRegistrationSubmitted = action.payload;
    },
    setOwnerRegistrationData(state, action) {
      state.ownerRegistrationData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoginLoading,
  logoutUser,
  loginSuccess,
  getMeSuccess,
  setGetMeLoading,
  setResetPasswordLoading,
  setResetPasswordSubmitted,
  setOwnerRegistrationLoading,
  setOwnerRegistrationSubmitted,
  setOwnerRegistrationData,
  setLoginData,
} = authSlice.actions;

export const loginUser =
  ({ email, password , captchaToken}) =>
  async (dispatch) => {
    dispatch(setLoginLoading(true));
    try {
      const response = await loginUserAPI({ email, password, captchaToken });
      // dispatch(setSubmitContactLoading(false));
      dispatch(loginSuccess(response.data.data));
      dispatch(setLoginLoading(false));
      return response.data.data
    } catch (err) {
      console.log(err);
      dispatch(setLoginLoading(false));

      throw new Error(err.response?.data?.message);
    }
  };

export const getMe = () => async (dispatch) => {
  dispatch(setGetMeLoading(true));
  try {
    const response = await getMeApi();
    // dispatch(setSubmitContactLoading(false));
    dispatch(getMeSuccess(response.data.data));
    dispatch(setGetMeLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(setGetMeLoading(false));
    throw new Error(err.response?.data?.message);
  }
};

export const requestResetPassword = (email) => async (dispatch) => {
  dispatch(setResetPasswordLoading(true));
  try {
    const response = await requestResetPasswordApi({ email });
    dispatch(setResetPasswordSubmitted(true));
    dispatch(setResetPasswordLoading(false));
  } catch (err) {
    console.log(err);
    dispatch(setResetPasswordLoading(false));
    throw new Error(err.response?.data?.message);
  }
};

export const submitResetPassword =
  ({ password, token }) =>
  async (dispatch) => {
    dispatch(setResetPasswordLoading(true));
    try {
      const response = await submitResetPasswordApi({ password, token });
      console.log({ response });
      dispatch(setResetPasswordLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setResetPasswordLoading(false));
      throw new Error(err.response?.data?.message);
    }
  };

export default authSlice.reducer;

export const initOwnerRegistration = (data) => async (dispatch) => {
  dispatch(setOwnerRegistrationLoading(true));
  try {
    const response = await initOwnerRegistrationApi(data);
    dispatch(setOwnerRegistrationSubmitted(true));
    dispatch(setOwnerRegistrationLoading(false));
  } catch (err) {
    dispatch(setOwnerRegistrationLoading(false));
    throw new Error(err.response?.data?.message);
  }
};

export const verifyOwnerRegistrationToken = (token) => async (dispatch) => {
  dispatch(setOwnerRegistrationLoading(true));
  try {
    const response = await verifyOwnerRegistrationTokenApi(token);
    dispatch(setOwnerRegistrationData(response.data.data));
    dispatch(setOwnerRegistrationLoading(false));
  } catch (err) {
    dispatch(setOwnerRegistrationLoading(false));
    throw new Error(err.response?.data?.message);
  }
};

export const submitOwnerRegistration = (data, token) => async (dispatch) => {
  dispatch(setOwnerRegistrationLoading(true));
  try {
    const response = await submitOwnerRegistrationApi(data, token);
    dispatch(setOwnerRegistrationSubmitted(true));
    dispatch(setLoginData(response.data.data));
    dispatch(setOwnerRegistrationLoading(false));
  } catch (err) {
    dispatch(setOwnerRegistrationLoading(false));
    throw new Error(err.response?.data?.message);
  }
};

export const registerOwner = (data) => async (dispatch) => {
  dispatch(setOwnerRegistrationLoading(true));
  try {
    const response = await registerOwnerApi(data);
    dispatch(setOwnerRegistrationSubmitted(true));
    dispatch(setLoginData(response.data.data));
    dispatch(setOwnerRegistrationLoading(false));
  } catch (err) {
    dispatch(setOwnerRegistrationLoading(false));
    throw new Error(err.response?.data?.message);
  }
};

