import { configureStore } from "@reduxjs/toolkit";
import dashboardListingSlice from "../features/dashboard/listings/listingSlice";
import dashboardOwnerSlice from "../features/dashboard/owner/ownerSlice";
import dashboardResortsSlice from "../features/dashboard/resort/resortSlice";
import resortSlice from "../features/resort/resortSlice";
import bookingSlice from "../features/booking/bookingSlice";
import contactSlice from "../features/contact/contactSlice";
import authSlice from "../features/auth/authSlice";
import globalSettingsSlice from "../features/globalSettings/globalSettingsSlice";
import reportSlice from "../features/dashboard/report/reportSlice";
import pageSlice from "../features/dashboard/page/pageSlice";
import dashboardBookingSlice from "../features/dashboard/bookings/bookingSlice";
import summarySlice from "../features/dashboard/summary/summarySlice";
import articleSlice from "../features/dashboard/article/articleSlice";
import settingsSlice from "../features/dashboard/settings/settingsSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    resort: resortSlice,
    booking: bookingSlice,
    contact: contactSlice,
    dashboardListing: dashboardListingSlice,
    dashboardResort: dashboardResortsSlice,
    dashboardOwner: dashboardOwnerSlice,
    globalSettings: globalSettingsSlice,
    dashboardReport: reportSlice,
    dashboardPage: pageSlice,
    dashboardArticle: articleSlice,
    dashboardSummary: summarySlice,
    dashboardBooking: dashboardBookingSlice,
    settings: settingsSlice,
  },
});
