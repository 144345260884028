import request from "../../../app/utils/request";

export function isFreeListingEnabled() {
  return request.get(`/settings/freeListing`);
}

export function updateIsFreeListingEnabled(isFreeListingEnabled) {
  return request.post(`/settings/freeListing`, {
    isFreeListingEnabled,
  });
}

export function getBannersApi() {
  return request.get(`/settings/banners`);
}

export function updateBannersApi({ id, text, isVisible }) {
  return request.put(`/settings/banners/${id}`, {
    text,
    isVisible,
  });
}
