import qs from "querystring";
import request from "../../../app/utils/request";

export function fetchPagesApi(params) {
  return request.get(`/dashboard/page?${qs.encode(params)}`);
}

export function fetchPageBySlugApi(slug) {
  return request.get(`/dashboard/page/by_slug/${slug}`);
}

export function createPageApi(data) {
  return request.post("/dashboard/page", data);
}

export function updatePageApi(id, data) {
  return request.put(`/dashboard/page/${id}`, data);
}

export function toggleDisablePageApi(id) {
  return request.post(`/dashboard/page/${id}/toggle-disabled`);
}

export function deletePageApi(id) {
  return request.delete(`/dashboard/page/${id}`);
}
