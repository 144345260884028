import qs from "querystring";
import request from "../../../app/utils/request";

export function searchListingsApi(params) {
  return request.get(`/dashboard/listing/search?${qs.encode(params)}`);
}

export function fetchListingsApi(params) {
  return request.get(`/dashboard/listing?${qs.encode(params)}`);
}

export function fetchListingByIdApi(id) {
  return request.get(`/dashboard/listing/${id}`);
}

export function initCreateListingApi(data) {
  return request.post("/dashboard/listing/init", data);
}

export function fetchListingPaymentStatusApi(params) {
  return request.get(`/dashboard/listing/payment-status?${qs.encode(params)}`);
}

export function createListingApi(data) {
  return request.post("/dashboard/listing", data);
}

export function updateListingApi(id, data) {
  return request.put(`/dashboard/listing/${id}`, data);
}

export function toggleListingVerifiedApi(id) {
  return request.post(`/dashboard/listing/${id}/toggle-verified`);
}

export function preVerifyListingAPI(data) {
  return request.post(`/dashboard/listing/pre-verify`, data);
}

export function preVerifyListingResIdAPI(data) {
  return request.post(`/dashboard/listing/pre-verify/res-id`, data);
}

export function deleteListingApi(id, data) {
  return request.post(`/dashboard/listing/${id}/deactivate`, data);
}
