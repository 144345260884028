import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalLoaderVisible: false,
  globalLoaderMessage: "",
};

export const globalSettingsSlice = createSlice({
  name: "globalSettingsSlice",
  initialState,
  reducers: {
    setGlobalLoaderVisible(state, action) {
      state.globalLoaderVisible = action.payload;
    },
    setGlobalLoaderMessage(state, action) {
      state.globalLoaderMessage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGlobalLoaderMessage, setGlobalLoaderVisible } =
  globalSettingsSlice.actions;

export default globalSettingsSlice.reducer;
