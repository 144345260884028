import { createSlice } from "@reduxjs/toolkit";
import {
  fetchResortViewsApi,
  fetchDashboardResortsApi,
  deleteResortApi,
  createResortApi,
  fetchDashboardResortByIdApi,
  updateResortApi,
  createResortPictureApi,
} from "./resortApi";

const initialState = {
  resortViews: [],

  dashboardResorts: [],
  dashboardResort: null,
  dashboardResortsCount: 0,
  dashboardResortsLoading: false,
  dashboardCreatePictureLoading: false,
};

export const resortSlice = createSlice({
  name: "dashboardResort",
  initialState,
  reducers: {
    setResortViews(state, action) {
      state.resortViews = action.payload;
    },
    setDashboardResortsLoading(state, action) {
      state.dashboardResortsLoading = action.payload;
    },
    setDashboardResorts(state, action) {
      state.dashboardResorts = action.payload.resorts;
      state.dashboardResortsCount = action.payload.count;
    },
    setDashboardResort(state, action) {
      state.dashboardResort = action.payload.resort;
    },
    setDashboardCreatePictureLoading(state, action) {
      state.dashboardCreatePictureLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setResortViews,
  setDashboardResorts,
  setDashboardResortsLoading,
  setDashboardResort,
  setDashboardCreatePictureLoading,
} = resortSlice.actions;

export const fetchResortViews = () => async (dispatch) => {
  try {
    const response = await fetchResortViewsApi();
    dispatch(setResortViews(response.data.data.resortViews));
  } catch (err) {
    console.log(err);
  }
};

export const fetchDashboardResorts = (params) => async (dispatch) => {
  dispatch(setDashboardResortsLoading(true));
  try {
    const response = await fetchDashboardResortsApi(params);
    dispatch(setDashboardResorts(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setDashboardResortsLoading(false));
};

export const fetchDashboardResortById = (id) => async (dispatch) => {
  dispatch(setDashboardResortsLoading(true));
  dispatch(setDashboardResort({ resort: null }));
  try {
    const response = await fetchDashboardResortByIdApi(id);
    dispatch(setDashboardResort(response.data.data));
  } catch (err) {
    console.log(err);
  }
  dispatch(setDashboardResortsLoading(false));
};

export const createResort = (data) => async (dispatch) => {
  dispatch(setDashboardResortsLoading(true));
  try {
    const response = await createResortApi(data);
    dispatch(setDashboardResortsLoading(false));
    return response.data.data.resort;
  } catch (err) {
    dispatch(setDashboardResortsLoading(false));
    throw err;
  }
};

export const updateResort = (id, data) => async (dispatch) => {
  dispatch(setDashboardResortsLoading(true));
  try {
    const response = await updateResortApi(id, data);
    dispatch(setDashboardResortsLoading(false));
    return response.data.data.resort;
  } catch (err) {
    dispatch(setDashboardResortsLoading(false));
    throw err;
  }
};

export const deleteResort = (id) => async (dispatch) => {
  dispatch(setDashboardResortsLoading(true));
  try {
    await deleteResortApi(id);
    dispatch(setDashboardResortsLoading(false));
  } catch (err) {
    dispatch(setDashboardResortsLoading(false));
    throw err;
  }
};

export const createResortPicture = (id, file) => async (dispatch) => {
  dispatch(setDashboardCreatePictureLoading(true));
  try {
    const response = await createResortPictureApi(id, file);
    dispatch(setDashboardCreatePictureLoading(false));
    return Promise.resolve(response.data.data)
  } catch (err) {
    dispatch(setDashboardCreatePictureLoading(false));
    throw err;
  }
};

export default resortSlice.reducer;
