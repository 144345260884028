import qs from "querystring";
import request from "../../app/utils/request";

export function fetchBookingStatusAPI(params) {
  return request.get(`/booking/status?${qs.encode(params)}`);
}

export function initBookingAPI(data) {
  return request.post(`/booking/init`, data);
}

export function verifyCouponAPI(data) {
  return request.post(`/booking/verify-coupon`, data);
}