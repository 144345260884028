import axios from "axios";
import qs from "querystring";
import request from "../../app/utils/request";

export function fetchResortAPI(resortId, params) {
  return request.get(`/resort/${resortId}?${qs.encode(params)}`);
}

export function fetchResortListingAPI(listingId) {
  return request.get(`/listing/${listingId}`);
}

export function inquireListingAPI(listingId, data) {
  return request.post(`/listing/${listingId}/inquire`, data);
}

export function fetchResortAddressLongLatAPI(address) {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?${qs.encode({
      address,
      language: "en",
      key: process.env.REACT_APP_GOOGLE_PUBLIC_KEY,
    })}`
  );
}

export function fetchTopResortsAPI() {
  return request.get(`/resort/top`);
}

export function fetchTopDestinatiosAPI() {
  return request.get(`/resort/top-destinations`);
}

export function searchResortsApi(params) {
  return request.get(`/resort/search?${qs.encode(params)}`);
}

export function fetchResortViewsApi() {
  return request.get("/dashboard/resort/views");
}
