import qs from "querystring";
import request from "../../../app/utils/request";

export function fetchCurrentListingsApi(params) {
  return request.get(`/dashboard/report/current_listings?${qs.encode(params)}`);
}

export function fetchActivitySummaryByYearApi(params) {
  return request.get(`/dashboard/report/activity_summary_by_year?${qs.encode(params)}`);
}

export function fetchActivitySummaryByResortApi(params) {
  return request.get(`/dashboard/report/activity_summary_by_resort?${qs.encode(params)}`);
}

export function fetchDashboardSummaryApi(params) {
  return request.get(`/dashboard/report/dashboard_summary?${qs.encode(params)}`);
}

export function fetchDBSummaryApi(params) {
  return request.get(`/dashboard/report/db_summary_by_year?${qs.encode(params)}`);
}

