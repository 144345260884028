import "./App.css";
import { Route, Routes } from "react-router";
import React, { Suspense, useEffect } from "react";
import HomeLayout from "./Layouts/HomeLayout";
import IsAuthenticated from "./app/hoc/IsAuthenticated";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchPages } from "./features/dashboard/page/pageSlice";
import DashboardLayout from "./Layouts/DashboardLayout";

const Home = React.lazy(() => import("./pages/home/index"));
const ResortListing = React.lazy(() => import("./pages/resorts/listing"));
const BookingCompleted = React.lazy(() =>
  import("./pages/resorts/listing/[id]/booking-completed")
);
const Book = React.lazy(() => import("./pages/book"));
const ResortDetailsPage = React.lazy(() => import("./pages/resorts/[id]"));

// Dashboard
const Dashboard = React.lazy(() => import("./pages/dashboard"));
// Auth
const DashboardLoginRedirect = React.lazy(() =>
  import("./pages/dashboard/login")
);
const DashboardLogin = React.lazy(() => import("./pages/dashboard/auth/login"));
const DashboardResetPassword = React.lazy(() =>
  import("./pages/dashboard/auth/reset-password")
);
const DashboardResetPasswordSubmit = React.lazy(() =>
  import("./pages/dashboard/auth/reset-password/submit")
);
// Auth Owner
const DashboardRegisterOwnerInit = React.lazy(() =>
  import("./pages/dashboard/auth/register/owner")
);
const DashboardRegisterOwnerComplete = React.lazy(() =>
  import("./pages/dashboard/auth/register/owner/complete")
);

//
const DashboardListing = React.lazy(() => import("./pages/dashboard/listing"));
const ServicePlans = React.lazy(() =>
  import("./pages/dashboard/listing/service-plans")
);
const ListingCompleted = React.lazy(() =>
  import("./pages/dashboard/listing/listing-completed")
);
const DashboardListingNew = React.lazy(() =>
  import("./pages/dashboard/listing/new")
);
const DashboardListingEdit = React.lazy(() =>
  import("./pages/dashboard/listing/edit")
);
const DashboardProfile = React.lazy(() => import("./pages/dashboard/profile"));
const DashboardResorts = React.lazy(() => import("./pages/dashboard/resort"));
const DashboardResortNew = React.lazy(() =>
  import("./pages/dashboard/resort/new")
);
const DashboardResortEdit = React.lazy(() =>
  import("./pages/dashboard/resort/edit")
);

// Dashboard report
const CurrentListingsReport = React.lazy(() =>
  import("./pages/dashboard/report/current_listings")
);
const ActivitySummaryByYearReport = React.lazy(() =>
  import("./pages/dashboard/report/activity_summary_by_year")
);
const ActivitySummaryByResortReport = React.lazy(() =>
  import("./pages/dashboard/report/activity_summary_by_resort")
);

// Dashboard Page
const DashboardPage = React.lazy(() => import("./pages/dashboard/page"));
const DashboardPageNew = React.lazy(() => import("./pages/dashboard/page/new"));
const DashboardPageEdit = React.lazy(() =>
  import("./pages/dashboard/page/edit")
);

// Bookings
const DashboardBookings = React.lazy(() => import("./pages/dashboard/booking"));
const DashboardBookingDetails = React.lazy(() =>
  import("./pages/dashboard/booking/[id]")
);

const Settings = React.lazy(() => import("./pages/dashboard/settings"));

// Custom Pages
const PageBySlug = React.lazy(() => import("./pages/dashboard/[slug]"));

// Articles
const DashboardArticle = React.lazy(() => import("./pages/dashboard/article"));
const DashboardArticleNew = React.lazy(() =>
  import("./pages/dashboard/article/new")
);
const DashboardArticleEdit = React.lazy(() =>
  import("./pages/dashboard/article/edit")
);
const ArticleBySlug = React.lazy(() => import("./pages/dashboard/blog/[slug]"));
const Blog = React.lazy(() => import("./pages/dashboard/blog"));
const BookingByLinkId = React.lazy(() => import("./pages/booking/[id]"));

function App() {
  const { globalLoaderVisible, globalLoaderMessage } = useSelector(
    (state) => state.globalSettings
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPages({ page: 1, perPage: 100 }));
  }, []);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {globalLoaderVisible && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2,
            backgroundColor: "rgba(0,0,0,0.8)",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              // backgroundColor: "black",
              // color: "#ff7f47",
              color: "white",
              padding: "1rem",
              borderRadius: 10,
              textAlign: "center",
            }}
          >
            <Spin size="large" />
            <br />
            {globalLoaderMessage}
          </p>
        </div>
      )}
      <HomeLayout>
        <Routes>
          <Route path="/book" element={<Book />} />
          <Route path="/bookings/:linkId" element={<BookingByLinkId />} />
          <Route
            path="/resorts/:resortId/listing/:listingId/booking-completed"
            element={<BookingCompleted />}
          />
          <Route
            path="/resorts/listing/:resortId"
            element={<ResortListing />}
          />
          <Route path="/resorts/:resortId" element={<ResortDetailsPage />} />
          {/* TODO: FALLBACK, REMOVE LATER WITH 404 PAGE */}
          <Route path="/" element={<Home />} />
          <Route path="/dashboard/login" element={<DashboardLoginRedirect />} />
          <Route
            path="/dashboard"
            element={<IsAuthenticated component={Dashboard} />}
          />
          {/* Auth */}
          <Route path="/dashboard/auth/login" element={<DashboardLogin />} />
          <Route
            path="/dashboard/auth/reset-password"
            element={<DashboardResetPassword />}
          />
          <Route
            path="/dashboard/auth/reset-password/submit"
            element={<DashboardResetPasswordSubmit />}
          />

          {/* Auth Owner */}
          <Route
            path="/dashboard/auth/register/owner"
            element={<DashboardRegisterOwnerInit />}
          />
          <Route
            path="/dashboard/auth/register/owner/complete"
            element={<DashboardRegisterOwnerComplete />}
          />

          {/* Listings */}
          <Route
            path="/dashboard/listings"
            element={<IsAuthenticated component={DashboardListing} />}
          />
          <Route
            path="/dashboard/listings/service-plans"
            element={<IsAuthenticated component={ServicePlans} />}
          />
          <Route
            path="/dashboard/listings/listing-completed"
            element={<IsAuthenticated component={ListingCompleted} />}
          />
          <Route
            path="/dashboard/listings/new"
            element={<IsAuthenticated component={DashboardListingNew} />}
          />
          <Route
            path="/dashboard/listings/:id/edit"
            element={<IsAuthenticated component={DashboardListingEdit} />}
          />

          {/* Resorts */}
          <Route
            path="/dashboard/resorts"
            element={<IsAuthenticated component={DashboardResorts} />}
          />
          <Route
            path="/dashboard/resorts/new"
            element={<IsAuthenticated component={DashboardResortNew} />}
          />
          <Route
            path="/dashboard/resorts/:id/edit"
            element={<IsAuthenticated component={DashboardResortEdit} />}
          />

          {/* Booking */}
          <Route
            path="/dashboard/bookings"
            element={<IsAuthenticated component={DashboardBookings} />}
          />
          <Route
            path="/dashboard/bookings/:id"
            element={<IsAuthenticated component={DashboardBookingDetails} />}
          />

          <Route
            path="/dashboard/settings"
            element={<IsAuthenticated component={Settings} />}
          />

          {/* Profile */}
          <Route
            path="/dashboard/profile"
            element={<IsAuthenticated component={DashboardProfile} />}
          />

          {/* Report */}
          <Route
            path="/dashboard/report/current_listings"
            element={<IsAuthenticated component={CurrentListingsReport} />}
          />
          <Route
            path="/dashboard/report/activity_summary_by_year"
            element={
              <IsAuthenticated component={ActivitySummaryByYearReport} />
            }
          />
          <Route
            path="/dashboard/report/activity_summary_by_resort"
            element={
              <IsAuthenticated component={ActivitySummaryByResortReport} />
            }
          />

          {/* Page */}
          <Route
            path="/dashboard/page"
            element={<IsAuthenticated component={DashboardPage} />}
          />
          <Route
            path="/dashboard/page/new"
            element={<IsAuthenticated component={DashboardPageNew} />}
          />
          <Route
            path="/dashboard/page/:slug/edit"
            element={<IsAuthenticated component={DashboardPageEdit} />}
          />

          {/* Article */}
          <Route
            path="/dashboard/article"
            element={<IsAuthenticated component={DashboardArticle} />}
          />

          <Route
            path="/dashboard/article/new"
            element={<IsAuthenticated component={DashboardArticleNew} />}
          />
          <Route
            path="/dashboard/article/:slug/edit"
            element={<IsAuthenticated component={DashboardArticleEdit} />}
          />

          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<ArticleBySlug />} />

          <Route path="/:slug" element={<PageBySlug />} />

          <Route path="*" element={<ResortListing />} />
        </Routes>
      </HomeLayout>
    </Suspense>
  );
}

export default App;
