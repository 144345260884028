import { createSelector, createSlice, select } from "@reduxjs/toolkit";
// import {
//   setGlobalLoaderMessage,
//   setGlobalLoaderVisible,
// } from "../../globalSettings/globalSettingsSlice";
import {
  createPageApi,
  fetchActivitySummaryByResortApi,
  fetchActivitySummaryByYearApi,
  fetchCurrentListingsApi,
  fetchPageBySlugApi,
  fetchPagesApi,
  toggleDisablePageApi,
  updatePageApi,
} from "./pageApi";

const initialState = {
  pagesLoad: false,
  pages: [],

  pageBySlugLoading: false,
  pageBySlug: null,
};

export const pageSlice = createSlice({
  name: "dashboard/page",
  initialState,
  reducers: {
    setFetchPagesLoading(state, action) {
      state.pagesLoad = action.payload;
    },
    fetchPagesSuccess(state, action) {
      state.pages = action.payload;
    },
    setFetchPageBySlugLoading(state, action) {
      state.pageBySlugLoading = action.payload;
    },
    fetchPageBySlugSuccess(state, action) {
      state.pageBySlug = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFetchPagesLoading,
  fetchPagesSuccess,
  fetchPageBySlugSuccess,
  setFetchPageBySlugLoading,
} = pageSlice.actions;

export const createPage = (data) => async (dispatch) => {
  const response = await createPageApi(data);
  return response.data.data;
};

export const updatePage = ({PageId, ...rest}) => async (dispatch) => {
  const response = await updatePageApi(PageId, rest);
  return response.data.data;
};


export const fetchPages = (params) => async (dispatch) => {
  dispatch(setFetchPagesLoading(true));
  try {
    const response = await fetchPagesApi(params);
    dispatch(fetchPagesSuccess(response.data.data.pages));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchPagesLoading(false));
};

export const fetchPageBySlug = (params) => async (dispatch) => {
  dispatch(setFetchPageBySlugLoading(true));
  dispatch(fetchPageBySlugSuccess(null));
  try {
    const response = await fetchPageBySlugApi(params);
    dispatch(fetchPageBySlugSuccess(response.data.data.page));
  } catch (err) {
    console.log(err);
  }
  dispatch(setFetchPageBySlugLoading(false));
};

export const toggleDisablePage = (id) => async (dispatch) => {
  const response = await toggleDisablePageApi(id);
  return response.data.data;
};


export default pageSlice.reducer;
